import React from 'react';
import "../../sass/shortcode/announcement.scss"


export default function Lunakidneybye() {
  return (
    <>
     <div className="lunabyenow">
        <div className='lunabyenow_in'>

      
           <a
            href="https://api.whatsapp.com/send/?phone=%2B919111606162&text=Hello%21+I+need+help+with+your+service.&type=phone_number&app_absent=0"
            target="_blank"
            rel="noopener noreferrer"
           
          >
            <h2 className="cs_sidebar_widget_title" style={{ margin: 0 }}>
            <span className='lunatxt'>Luna</span><span className='lunakidneytxt'>KIDNEY</span> <span className='tablettxt'>Tablets</span></h2>
            <h4 className="clickable">Click now and place your order effortlessly!</h4>
          </a>
      
          </div>
        </div>
        </>
   
  );
}
