import React from "react";
import "../../sass/shortcode/_about.scss";
import Section from "../Section";
import KidneyBreadcrumStyle from "../Breadcrumb/KidneyBreadcrumStyle";
import AboutBannerSectionStyle from "../Section/BannerSection/AboutBannerSectionStyle";
import Spacing from "../Spacing";
import btnArrowUrl from "../../images/icons/arrow_white.svg";
// import AboutSlider from "./NewAboutSlider";
import { useTranslation } from "react-i18next";
import { aboutDatas } from "./about";
import TeamSection from "../Section/TeamSection";
import AwardSectionStyle2 from "../Section/AwardSection/AwardSectionStyle2";
// import BannerSectionStyle4 from "../Section/BannerSection/BannerSectionStyle4";
// import FeaturesSectionStyle6 from "../Section/FeaturesSection/FeaturesSectionStyle6";
import { pageTitle } from "../../helpers/PageTitle";
import ProductBlogSection from "./ProductBlogSection";
// import SingleGridAccordion from "../SingleGridAccordion/SingleGridAccordion";
// import List from "../List";
import Community from "../../images/diet/Community1.png";
import Research from "../../images/diet/Research.png";
import icon1 from "../../images/icons/HowitWorks1.svg";
import icon2 from "../../images/icons/GettingStarted2.svg";
import icon3 from "../../images/icons/message.svg";
// import impact1 from "../../images/icons/award2.svg"
// import WorkingProcess from "../Section/WorkingProcess";
import HistoryProcess from "./historyprogress";
import SectionHeading from "../SectionHeading";
import Core1 from "../../images/diet/coreicon1.png";
import Core2 from "../../images/diet/coreicon2.png";
import Core3 from "../../images/diet/coreicon3.png";
import Core4 from "../../images/diet/coreicon4.png";
import hreat from "../../images/diet/heart.svg";
import Innovation from "../../images/diet/innovation.svg";
import Communitys from "../../images/diet/community.svg";
import Impact1 from "../../images/diet/impacts1.svg";
import Impact2 from "../../images/diet/impacts2.svg";
import Impact3 from "../../images/diet/impacts3.svg";

const NewAbouts = () => {
  pageTitle("Abouts Us");
  const { t } = useTranslation(["about"]);
  const {
    // MissionVisionValues,
    OurAwarddata,
    // OurteamData,
    Ourproductdata,
    historydata,
  } = aboutDatas();

  return (
    <>
      <Section className="cs_kideny_education">
        <KidneyBreadcrumStyle />
      </Section>
      <div className="bg_color_abou">
        <div className="About_container">
          <Section className="cs_banner_styles">
            <AboutBannerSectionStyle
              // bgUrl="/images/about/banner_bg_2.jpeg"
              title="KidneyNeeds"
              subHeadTitle="Your Complete Kidney Health Solution"
              customeThemeClass=""
              ptext="Your trusted partner for comprehensive kidney care, providing convenient access to expert guidance, affordable medications, advanced diagnostics, and personalized, holistic support to empower your well-being."
              videoSrcimg="/images/about/banner_bg_2.jpeg"
              cardtxt={
                <>TRANSFORM YOUR HEALTHCARE JOURNEY WITH AI INNOVATION!</>
              }
            />
          </Section>
        </div>

        <Section
          topMd={30}
          topLg={20}
          topXl={80}
          bottomMd={50}
          bottomLg={50}
          bottomXl={40}
        >
          <div className="container">
            <div className="About_ai_container container">
              <div className="About_ai_flex_container">
                <div className="About_ai_box ">
                  <div className="About_ai_box_in">
                    <div>
                      <h2 className="cs_post_title cs_semibold cs_fs_32">
                        Our Mission
                      </h2>
                      <p className="cs_member_description">
                        At KidneyNeeds, we simplify kidney care with expert
                        counseling, affordable medicines, and trusted dialysis
                        centers. Empowering patients with the right knowledge,
                        we help them take control of their health.{" "}
                      </p>
                    </div>
                  </div>

                  <div></div>
                </div>
                <div className="About_ai_box ">
                  <div className="About_ai_box_in">
                    <div>
                      <h2 className="cs_post_title cs_semibold cs_fs_32">
                        Our Vision
                      </h2>
                      <p className="cs_member_description">
                        It’s personal, connecting directly with patients. It
                        highlights the mission of making kidney care accessible
                        and trustworthy, and ends with a clear call to action,
                        encouraging patients to reach out for support.
                      </p>
                    </div>
                  </div>

                  <div></div>
                </div>
                <div className="About_ai_box ">
                  <div className="About_ai_box_in">
                    <div>
                      <h2 className="cs_post_title cs_semibold cs_fs_32">
                        Our Values
                      </h2>
                      <ul className="feature-list">
                        <li>
                          <span className="icon ">
                            {" "}
                            <img
                              src={hreat}
                              alt="Icon"
                              width={34}
                              height={34}
                            />
                          </span>
                          <div className="text">
                            <p className="title m-0">Care</p>
                            <p className="subtitle m-0 ">
                              Putting patients first
                            </p>
                          </div>
                        </li>
                        <li>
                          <span className="icon ">
                            <img
                              src={Innovation}
                              alt="Icon"
                              width={34}
                              height={34}
                            />
                          </span>
                          <div className="text">
                            <p className="title m-0">Innovation</p>
                            <p className="subtitle m-0">Advanced solutions</p>
                          </div>
                        </li>
                        <li>
                          <span className="icon ">
                            {" "}
                            <img
                              src={Communitys}
                              alt="Icon"
                              width={34}
                              height={34}
                            />
                          </span>
                          <div className="text">
                            <p className="title m-0">Community</p>
                            <p className="subtitle m-0">
                              Supporting each other
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>

        {/* Our Impact So Far */}

        <Section
          topMd={30}
          topLg={0}
          topXl={10}
          bottomMd={50}
          bottomLg={50}
          bottomXl={40}
        >
          <h1 className="center">Our Impact So Far</h1>
          <Spacing md="20" lg="10" />
          <div className="container aboutimpact_container">
            <div className="cs_iconbox cs_style_5 cs_white_bg cs_radius_15  widthab">
              {/* <div className="cs_iconbox_icon cs_center cs_accent_bg cs_radius_15"> */}
              <img
                className=""
                loading="lazy"
                src={Impact1}
                alt="Icon"
                width={50}
                height={50}
              />
              {/* </div> */}
              <h2 className="cs_iconbox_title m-0 cs_fs_20 cs_medium">
                <span className="cs_fs_32">10,000+ </span> Kidney Patients
                Supported
              </h2>
            </div>
            <div className="cs_iconbox cs_style_5 cs_white_bg cs_radius_15 widthab abgap">
              {/* <div className="cs_iconbox_icon cs_center cs_accent_bg cs_radius_15"> */}
              <img
                className=""
                loading="lazy"
                src={Impact2}
                alt="Icon"
                width={50}
                height={50}
              />
              {/* </div> */}
              <h2 className="cs_iconbox_title m-0 cs_fs_20 cs_medium">
                {" "}
                <span className="cs_fs_32">₹1 Crore+ </span> Saved in Affordable
                Medicines & Care
              </h2>
            </div>
          </div>
          <div>
            <Spacing md="40" lg="10" />
            <div className="container aboutimpact_container">
              <div className="cs_iconbox cs_style_5 cs_white_bg cs_radius_15 widthab ">
                {/* <div className="cs_iconbox_icon cs_center cs_accent_bg cs_radius_15"> */}
                <img
                  className=""
                  loading="lazy"
                  src={Impact3}
                  alt="Icon"
                  width={50}
                  height={50}
                />
                {/* </div> */}
                <h2 className="cs_iconbox_title m-0 cs_fs_20 cs_medium">
                  {" "}
                  <span className="cs_fs_32">Trusted </span> by Leading
                  Nephrologists & Caregiver
                </h2>
              </div>
            </div>
          </div>
        </Section>

        {/* History */}
        <Section
          bottomMd={130}
          bottomLg={120}
          bottomXl={110}
          className="cs_service-catheters cs_white_bg"
        >
          <Spacing md="40" lg="40" />
          <HistoryProcess
            sectionTitle="History"
            //  sectionSubTitle={`${t("whyChooseUs.description")}`}
            sectionTitleUp=""
            sectionTitleDown=""
            data={historydata}
          />
        </Section>

        {/* service */}
        <Section
          topMd={30}
          topLg={0}
          topXl={0}
          bottomMd={50}
          bottomLg={50}
          bottomXl={40}
        >
          <div>
            <h1 className="center">Why KidneyNeeds?</h1>
            <div className="WhyKidneyNeeds_container container">
              <p className="m-0 cs_member_description cs_hero_subtitle cs_fs_18 m-0">
                At KidneyNeeds, we provide personalized support, offering
                one-on-one expert guidance to kidney patients. Our focus on
                affordable care ensures access to low-cost medicines and
                financial solutions, making treatment more accessible. With a
                comprehensive range of kidney health services, we assist with
                everything from prescriptions to tailored diet plans. Trusted by
                both doctors and patients, our dedicated team empowers you to
                understand your treatment, follow prescriptions diligently, and
                take control of your kidney health. While we don’t replace
                doctors, we enhance your journey by ensuring you receive the
                right care and support.
              </p>
            </div>
          </div>
        </Section>

        {/* <Section
          topMd={30}
          topLg={0}
          topXl={10}
          bottomMd={50}
          bottomLg={50}
          bottomXl={40}
        >
          <div className="ourStory_container container">
            <div className="About_ai_heading_container">
              <div>
                <h2 className="cs_section_title cs_fs_72 m-0">
                  How We Started History - KidneyNeeds
                </h2>
              </div>
            </div>
            <Spacing md="40" lg="10" />

            <AboutSlider />
          </div>
        </Section> */}

        <Section topMd={10} topXl={15}>
          <TeamSection
            sectionTitle={t("OurteamData.sectionTitle")}
            // sectionTitleUp={t("OurteamData.sectionTitleUp")}
            // data={OurteamData}
          />
        </Section>

        <Section
          topMd={30}
          topLg={0}
          topXl={50}
          bottomMd={30}
          bottomLg={30}
          bottomXl={40}
        >
          <div className="container">
            <SectionHeading
              title="Core Values"
              subTitle="Built on Experience, Driven by Compassion At KidneyNeeds, we believe in helping patients beyond treatment—by empowering them to help others. Our first team members were not just professionals; they were people who had lived through the struggles of kidney disease in their own families."
              variantColor="cs_white_color"
              center
            />

            <div className="CoreValues_container">
              <div className=" cs_iconbox cart cs_white_bg cs_radius_15">
                <img src={Core1} alt="img" width={80} height={80} />
                <p>
                  Educating patients about their condition and treatment plans.
                </p>
              </div>
              <div className="cs_iconbox cart cs_white_bg cs_radius_15">
                <img src={Core2} alt="img" width={80} height={80} />
                <p>
                  {" "}
                  Offering dietary guidance based on real scientific evidence.
                </p>
              </div>
              <div className="cs_iconbox cart cs_white_bg cs_radius_15">
                <img src={Core3} alt="img" width={80} height={80} />
                <p>
                  {" "}
                  Helping them save on treatment costs through affordable
                  alternatives.
                </p>
              </div>
              <div className="cs_iconbox cart cs_white_bg cs_radius_15">
                <img src={Core4} alt="img" width={80} height={80} />
                <p>Encouraging early screening for better kidney health.</p>
              </div>
            </div>
          </div>
        </Section>

        <Section
          topMd={30}
          topLg={0}
          topXl={50}
          bottomMd={30}
          bottomLg={30}
          bottomXl={40}
        >
          <div className="container">
            <SectionHeading
              title="From a Daughter’s Struggle to a Mission of Hope"
              subTitle="It all started with a journey—a daughter carrying her father 30 km up and down the hills for every dialysis session, facing the challenges of finding care, affording medicines, and understanding kidney disease. This struggle wasn’t just personal; it was a reality for thousands of kidney patients across India."
              variantColor="cs_white_color"
              subTitlesub="Determined to bridge the gaps in kidney care, she left her routine work and built KidneyNeeds—a platform that ensures no patient feels lost, unsupported, or financially burdened."
              center
            />
          </div>
        </Section>
        <Section
          topMd={10}
          topLg={0}
          topXl={10}
          bottomMd={200}
          bottomLg={40}
          bottomXl={70}
        >
          <AwardSectionStyle2
            sectionTitle={t("AwardSectiondata.sectionTitle")}
            sectionTitleUp={t("AwardSectiondata.sectionTitleUp")}
            sectionSubTitle={t("AwardSectiondata.sectionSubTitle")}
            data={OurAwarddata}
          />
        </Section>

        <div className="container">
          {/* <Section
            topMd={30}
            topLg={20}
            topXl={10}
            bottomMd={50}
            bottomLg={50}
            bottomXl={40}
          >
            <div className="oneClickSolution_container container">
              <h1 className="cs_section_title cs_fs_72 m-0 center">
                One Click Solution
              </h1>
              <Spacing md="15" lg="100" />
            </div>
            <div className="oneClickSolution_con container">
              <div className="oneClickSolution_con_content">
                <div className="oneClickSolution_con_content_in">
                  <h4 className="cs_section_title cs_fs_32 m-0">
                    Simplified Healthcare Access
                  </h4>

                  <p className="cs_member_designation cs_fs_18 mr_top cs_heading_color">
                    Our innovative one-click solution streamlines the entire
                    kidney care process, from appointment scheduling to
                    treatment monitoring, making healthcare more
                    accessible than ever.
                  </p>

                  <button className="cs_btn cs_style_1">
                    <span>Learn More</span>
                    <i>
                      <img loading="lazy" src={btnArrowUrl} alt="Icon" />
                      <img loading="lazy" src={btnArrowUrl} alt="Icon" />
                    </i>
                  </button>
                </div>
              </div>
              <div className="oneClickSolution_con_image">
              
              </div>
            </div>

           <div className="spacing_about"></div>
            <FeaturesSectionStyle6 
            sectionTitle="Village and City - Initiative"
            subHeadTitle="One Click Solution"
            image1="images/about/Citydoc.png"
            image2="images/about/Villagedoc.png"
          />





          </Section> */}

          <Section
            topMd={30}
            topLg={20}
            topXl={10}
            bottomMd={50}
            bottomLg={50}
            bottomXl={40}
          >
            <div className="OurInitiatives_container container">
              <h1 className="cs_section_title cs_fs_72 m -0 center">
                Our Initiatives
              </h1>
            </div>
            <div className="container">
              <div className="OurInitiatives_containers ">
                <div className="OurInitiatives_containers1">
                  <h3 className="cs_section_title cs_fs_32 m-0 ">
                    Community Outreach
                  </h3>
                  <p className="cs_member_designation cs_fs_18 mr_top cs_heading_color">
                    Regular health camps and awareness programs to educate
                    communities about kidney health and prevention.
                  </p>
                  <img src={Community} alt="img" />
                </div>
                <div className="OurInitiatives_containers2">
                  <h3 className="cs_section_title cs_fs_32 m-0 ">
                    Research & Development
                  </h3>
                  <p className="cs_member_designation cs_fs_18 mr_top cs_heading_color">
                    Continuous investment in research to develop innovative
                    kidney care solutions and treatments.
                  </p>
                  <img src={Research} alt="img" />
                </div>
              </div>
            </div>
          </Section>
        </div>
        <Section
          topMd={30}
          topLg={20}
          topXl={20}
          bottomMd={50}
          bottomLg={50}
          bottomXl={40}
        >
          <div className="About_ai_container container">
            <div className="About_ai_heading_container">
              <div>
                <p>Instant Care</p>
                <h2 className="cs_section_title cs_fs_72 ">
                  How KidneyNeeds Works
                </h2>
              </div>
            </div>
            <div className="About_ai_flex_container">
              <div className="About_ai_box">
                <div className="About_ai_box_in">
                  <div>
                    <h2 className="cs_iconbox_title m-0 cs_fs_20 cs_medium">
                      Our Founder Message for Patients
                    </h2>
                  </div>
                  <div className="About_icon1">
                    {" "}
                    {/* <i class="fa-solid fa-paper-plane"></i> */}
                    <img src={icon3} alt="How it Works" />
                  </div>
                </div>
                <Spacing md="30" lg="100" />
                <div>
                  <p className="cs_member_designation cs_fs_20 cs_heading_color">
                    Your Health, Simplified
                  </p>
                  <p className="cs_member_description ">
                    Being a patient is a difficult thing to happen. Hope should
                    not be missed in any prescription. Your kidney health
                    is our priority.
                  </p>
                </div>
              </div>
              <div className="About_ai_box">
                <div className="About_ai_box_in">
                  <div>
                    <h2 className="cs_iconbox_title m-0 cs_fs_20 cs_medium">
                      Getting Started
                    </h2>
                  </div>
                  <div className="About_icon2">
                    {" "}
                    {/* <i class="fa-solid fa-paper-plane"></i> */}
                    <img src={icon2} alt="How it Works" />
                  </div>
                </div>
                <Spacing md="30" lg="100" />
                <div>
                  <p className="cs_member_designation cs_fs_20 cs_heading_color">
                    Easy Steps to Health
                  </p>
                  <p className="cs_member_description ">
                    The importance of health is better understood when there is
                    a disease or illness. With the rise in chronic illnesses
                    like diabetes and hypertension, kidney diseases are on the
                    steep rise. Many times, kidney disease patients have one or
                    other organ involvement like the heart, nerve, or brain. The
                    impact of kidney health on someone’s life is huge.
                  </p>
                </div>
              </div>
              <div className="About_ai_box">
                <div className="About_ai_box_in">
                  <div>
                    <h2 className="cs_iconbox_title m-0 cs_fs_20 cs_medium">
                      How it Works
                    </h2>
                  </div>
                  <div className="About_icon3">
                    {" "}
                    {/* <i class="fa-solid fa-paper-plane"></i> */}
                    <img src={icon1} alt="How it Works" />
                  </div>
                </div>
                <Spacing md="30" lg="100" />
                <div>
                  <p className="cs_member_designation cs_fs_20 cs_heading_color">
                    Effortless-Driven Care
                  </p>
                  <p className="cs_member_description ">
                    The best way to have better kidney health is by preventing
                    kidney disease. However, once kidney disease sets in, there
                    may be complications. Many things can be predicted and
                    treated by experts. The cost of kidney care is huge. Doctor
                    do their best with their prescriptions. We found the
                    impending need for effective counselling about disease,
                    treatment, and medications. One of the most common
                    questions, patients have is to know about
                    the dietary aspect.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Section>

        {/* Start Blog Section */}
        <Section bottomMd={130} bottomLg={120} bottomXl={110}>
          <h1 className="center">Our Products</h1>
          <ProductBlogSection
            customeThemeClass={"bright-blogs"}
            sectionTitle=""
            sectionTitleUp=""
            data={Ourproductdata}
          />
        </Section>
        {/* End Blog Section */}

        {/* <Section
          topMd={30}
          topLg={20}
          topXl={80}
          bottomMd={50}
          bottomLg={50}
          bottomXl={40}
        >
          <div className="partner_section container">
          
          <img src={fourth} alt="32" />
            
            </div>
        </Section> */}
        <Section>
          <div className="container">
            <div className="green_container container">
              <div className="green_container_text">
                <h2 className="cs_hero_title cs_white_color cs_fs_72">
                  Start Your Health Journey
                </h2>
                <div className="greentext_para">
                  <p className="cs_member_description ">
                    It all started when one of the directors had to face the
                    challenge of kidney disease-related morbidity and economic
                    consequences in their family.The importance of health is
                    better understood when there is a disease or illness. With
                    the rise in chronic illnesses like diabetes and
                    hypertension, kidney diseases are on the steep rise. Many
                    times, kidney disease patients have one or other organ
                    involvement like the heart, nerve, or brain.
                  </p>
                </div>
                <div className="About_btn">
                  <button className="cs_btn cs_style_1">
                    <span>Find out More</span>
                    <i>
                      <img loading="lazy" src={btnArrowUrl} alt="Icon" />
                      <img loading="lazy" src={btnArrowUrl} alt="Icon" />
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Section>
        {/* <Spacing md="60" lg="100" />
        <Section className="cs_footer_margin_0">
          <BannerSectionStyle4
            bgUrl="images/about/Bottom-Banner-ab-new.png"
            title={t("AboutusMissedCall.title")}
            subTitle={t("AboutusMissedCall.subTitle")}
            content={t("AboutusMissedCall.content")}
            center
          />
        </Section> */}

        <Section
          topMd={30}
          topLg={100}
          topXl={70}
          bottomMd={50}
          bottomLg={10}
          bottomXl={40}
        >
          <div className="partner_section container">
            <div className="partner_section_left">
              <div className="partner_section_left_side1">
                <h1> Why Partner with Us?</h1>
                {/* <button className="cs_btnAb cs_style_1Ab">
                  <span className="cs_section_subtitle text-uppercase  cs_semibold ">
                    Why Partner with Us?
                  </span>
                </button> */}
              </div>
              <div className="partner_section_left_side2">
                <h3>
                  Innovative Solutions <br />
                 for India Based on Socio-Economic Challenges
                </h3>
              </div>
            </div>

            <div className="partner_section_right">
              <div className="partner_section_right_side1">
                <div className="partner_section_right_side1_in">
                  <div className="sm_boxNew1">
                    <h3>∞</h3>
                    <p className="cs_semibold">Strengthening Nephrology Workforce</p>
                  </div>
                  <div className="sm_boxNew2">
                    <h3>85+</h3>
                    <p className="cs_semibold">KidneyNeeds Screening Programs</p>
                  </div>
                </div>
              </div>
              <div className="partner_section_right_side2">
                <div className="partner_section_right_side2_in">
                  <div className="sm_boxNew3">
                    <h3>100%</h3>
                    <p className="cs_semibold">Online & Offline Monitoring</p>
                  </div>
                  <div className="sm_boxNew4">
                    <h3>5M</h3>
                    <p className="cs_semibold">Happy Healing Care Patients</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>

        {/* <Section
          topMd={190}
          topLg={45}
          topXl={75}
          bottomMd={20}
          //  bottomLg={150}
          //  bottomXl={110}
          className="cs_white_bg cs_transplant"
          // sectionRef={organDonorRef}
        >
        
        </Section> */}
      </div>
    </>
  );
};

export default NewAbouts;
