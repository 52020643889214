import React, { useState } from 'react';
import "../../sass/shortcode/announcement.scss";
import btnArrowUrl from "../../images/icons/arrow_white.svg";


export default function RecentAnnouncement({ title, data }) {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <div className="announcement_width cs_sidebar_item_change_color">
          <button
            onClick={handleClose}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "red",
              border: "none",
              background: "none",
              fontSize: "18px",
              cursor: "pointer",
            }}
          >
            X
          </button>
          <a
            href="https://api.whatsapp.com/send/?phone=%2B919111606162&text=Hello%21+I+need+help+with+your+service.&type=phone_number&app_absent=0"
            target="_blank"
            rel="noopener noreferrer"
            className="fixed_container"
          >
            
            <h2 className="cs_sidebar_widget_title" style={{ margin: 0 }}>
            <span className='lunatxt'>Luna</span><span className='lunakidneytxt'>KIDNEY</span>
            </h2>
            <h4 className="clickable">Click now and place your order effortlessly!</h4>
            <button className="cs_btnA cs_style_1A">
                <span>BUY NOW</span>
                <i>
                  <img loading="lazy" src={btnArrowUrl} alt="Icon" />
                  <img loading="lazy" src={btnArrowUrl} alt="Icon" />
                </i>
              </button>
          </a>
        </div>
      )}
    </>
  );
}
