import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
// import Footer from '../Footer';
import Header from "../Header";
import Footer from "../Footer/CutomeFooter/Footer";
import Smallnav from "../SmallHeader/smallnav";
import NewsTicker from "../Pages/NewsLine";

export default function Layout() {
  const location = useLocation();
  const isLoginPage = location.pathname === "/login" || location.pathname === "/sign-in";

  const topRef = useRef(null);
  const bottomRef = useRef(null);
  const [atBottom, setAtBottom] = useState(false);

  // Scroll to Top or Bottom
  const handleScroll = () => {
    if (atBottom) {
      topRef.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Check Scroll Position
  useEffect(() => {
    const checkScroll = () => {
      const isBottom =
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 10;
      setAtBottom(isBottom);
    };

    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  }, []);

  return (
    <>
     <div ref={topRef}></div>
     {!isLoginPage && <Smallnav logoSrc="/images/logo.png" variant="cs_heading_color" />}
      {!isLoginPage && <Header logoSrc="/images/logo.png" variant="cs_heading_color" />}
      {!isLoginPage && <NewsTicker logoSrc="/images/logo.png" variant="cs_heading_color" />}
   
      <Outlet />
      
      <button
        onClick={handleScroll}
        style={{
          position: "fixed",
          left: "15px", // Left side position
          bottom: "30px",
          width: "50px",
          height: "50px",
          // backdropFilter: "blur(10px)", // Blurred effect
          background: "rgba(255, 255, 255, 0.1)", // Very light transparency
          backdropFilter: "blur(10px) saturate(150%)", // Stronger glass effect
          WebkitBackdropFilter: "blur(10px) saturate(150%)",
          color: "black",
          border: "none",
          borderRadius: "10px", // Rounded square shape
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
          fontSize: "20px",
          zIndex: "999",
        }}
      >
        {/* Show ⬆ when at the bottom, ⬇ when at the top */}
        {atBottom ? (
          <i className="fa-solid fa-arrow-up" style={{ fontSize: "18px" }}></i>
        ) : (
          <i className="fa-solid fa-arrow-down" style={{ fontSize: "18px" }}></i>
        )}
      </button>
     {!isLoginPage && <Footer logoSrc="/images/logo.png" variant="cs_heading_color" />}
      <div ref={bottomRef}></div>
    </>
  );
}
