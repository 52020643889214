import React from "react";
import btnArrowUrl from "../../../images/icons/arrow_white.svg";
import voiceimg from "../../../images/diet/Kidneyneeds_about-us-floting-icon.png";
// import VideoModal from "../../VideoModal";
// import IntroVideo from "../../../images/Videos-about/kidneyneeds intro animation.mp4";
// import parse from "html-react-parser";
// import Button from "../../Button";
// import Spacing from "../../Spacing";

export default function AboutBannerSectionStyle({
  bgUrl,

  title,
  subHeadTitle = "",
  ptext,
  subTitle2 = "",
  btnText,
  btnUrl,
  customeThemeClass = "",
  videoSrcimg,
  cardtxt,
}) {
  return (
    <section
      className="  cs_banner cs_style_3 cs_bg_filed cs_kidney_education_banner cs_kidney_needs_banner "
      style={{ backgroundImage: `url(${bgUrl})` }}
    >
      <div className="container ">
        <div
          className="cs_banner_tex About_container_border"
          style={{
            border: "2px solid white",
            backgroundColor: "#fff",
            borderRadius: "10px",
          }}
        >
          <h3 className="cs_banner_title cs_fs_60" style={{ margin: 0 }}>
            {title}
          </h3>

          <div className="about_banner_container">
            <div className="banner_sub_section1">
              <h3 className="cs_banner_title cs_fs_60" style={{ margin: 0 }}>
                {subHeadTitle}
              </h3>
              {/* <Spacing md="10" lg="100" /> */}
              <p className="cs_hero_subtitle cs_fs_20 Abptextgap">{ptext}</p>
            </div>

            <div className="banner_sub_section2">
              {/* <button>Find out More</button> */}
              <button className="cs_btnA cs_style_1A">
                <span>Find out More</span>
                <i>
                  <img loading="lazy" src={btnArrowUrl} alt="Icon" />
                  <img loading="lazy" src={btnArrowUrl} alt="Icon" />
                </i>
              </button>
            </div>

            <div className="banner_sub_section3">
              <div className="banner_sub_section3_in cs_hero_bottom_left">
                {/* <VideoModal videoUrl={IntroVideo} variant="cs_white_color" /> */}
              </div>
            </div>
          </div>
          {/* <Spacing md="20" lg="100" /> */}
          <div className="about_banner_container2">
            <div className="card_containerlist">
              <div className="card_box1">
                <div className="card_box1_content">
                  <div className="card_box1_content1">
                    <div>
                      {" "}
                      <h5 style={{ margin: 0 }}>99%</h5>
                    </div>
                    <div>
                      <h5 style={{ margin: 0 }}>
                        <i class="fa-brands fa-gratipay"></i>
                      </h5>
                    </div>
                  </div>
                  <div className="card_box1_content2">
                    <h6>
                      Your health is our <br /> priority
                    </h6>
                  </div>
                </div>
                <div className="card_box1_image"></div>
              </div>
              <div className="card_box2">
                <div className="card_box2_content">
                  <h5 style={{ margin: 0 }}>{cardtxt}</h5>
                </div>
                <div className="card_box2_img"></div>
              </div>
            </div>
            <div className="content_container ">
              <div className="content_container_ab1">
                <h3 style={{ margin: 0 }}>Voices of Satisfation</h3>
              </div>
              <div className="content_container_ab2">
                <img src={voiceimg} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
