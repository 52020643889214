/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { setProfileLink } from '../../services/store/slices/profileSlice';


const GoogleLoginComponent = () => {
    const [profileavatharLink, setProfileAvatharLink] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (profileavatharLink) {
            dispatch(setProfileLink(profileavatharLink));
        }
    }, [profileavatharLink, dispatch]);

    //  const handleSuccess = async (credentialResponse) => {
    //     try {
    //         const credential = credentialResponse.credential;
    //         let decodedToken;
    //         try {
    //             decodedToken = jwtDecode(credential);
    //             setProfileAvatharLink(decodedToken.picture);
    //         } catch (error) {
    //             console.error("Invalid JWT:", error.message);
    //         }

    //         // Send the token to your backend
    //         const response = await axios.post('http://localhost:5000/api/auth/google', {
    //             tokenId: credential,
    //         });

    //         // Assuming the backend sends a response with a `success` property in `data`
    //         if (response.data.success) {
    //             alert(response.data.message);
    //             dispatch(setProfileLink(decodedToken.picture));
    //             setTimeout(() => navigate('/'), 100);
    //         } else {
    //             console.error("Login failed:", response.data.message);
    //         }
    //     } catch (error) {
    //         console.error('Error during login:', error.response?.data || error.message);
    //     }
    // };

    const handleSuccess = async (credentialResponse) => {
        try {
            const credential = credentialResponse.credential;
            let decodedToken;
            try {
                decodedToken = jwtDecode(credential);
                console.log(decodedToken)
                Cookies.set("ProfileName", decodedToken.name)
                setProfileAvatharLink(decodedToken.picture);
            } catch (error) {
                console.error("Invalid JWT:", error.message);
            }

            // Send the token to your backend
            const response = await axios.post('http://localhost:5000/api/auth/google', {
                tokenId: credential,
            });

            // Assuming the backend sends a response with a `success` property in `data`
            if (response.data.success) {
                alert(response.data.message);
                dispatch(setProfileLink(decodedToken.picture));
                setTimeout(() => navigate('/'), 100);
            } else {
                console.error("Login failed:", response.data.message);
            }
        } catch (error) {
            console.error('Error during login:', error.response?.data || error.message);
        }
    };

    const handleError = () => {
        console.log('Login Failed');
    };

    return (
        <div>
            <h2>Google Login</h2>
            <GoogleLogin
                onSuccess={handleSuccess}
                onError={handleError}
            />
        </div>
    );
};

export default GoogleLoginComponent;
