import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "../../sass/shortcode/findcenter.scss";

export default function Searchcenter() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const suggestions = [
    "Samuvel Healthcare Center- New Delhi",
    "Lal path delhi healthCare - delhi",
    "KidneyNeeds Mumbai  Center - Mumbai",
    "KidneyNeeds Bangalore Center - Bangalore",
    "KidneyNeeds Dialysis Center - Kolkata",
    "Covai KidneyNeeds Center - Coimbatore TN",
    "Chennai KidneyNeeds Center - Chennai TN",
  ];

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    filterSuggestions(query);
  };

  const filterSuggestions = (query) => {
    if (query.trim()) {
      setFilteredSuggestions(
        suggestions.filter((hospital) =>
          hospital.toLowerCase().includes(query.toLowerCase())
        )
      );
    } else {
      setFilteredSuggestions(suggestions); // Show all when empty
    }
  };

  const handleSearch = () => {
    const normalize = (str) => str.trim().toLowerCase();
    const matchedHospital = suggestions.find((hospital) =>
      normalize(hospital).includes(normalize(searchQuery))
    );

    if (matchedHospital) {
      navigate("/Searching-Results", { state: { hospital: matchedHospital } });
    } else {
      alert("No matching results. Please enter a valid hospital name.");
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion);
    setFilteredSuggestions([]);
  };

  return (
    <div className="search-section">
      <div className="search-container">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch();
          }}
        >
          <div className="container ">
            <TextField
              className="search_len"
              fullWidth
              label="Search by keyword, pincode, or hospital name"
              value={searchQuery}
              onChange={handleInputChange}
              onClick={() => setFilteredSuggestions(suggestions)} // Show all suggestions on click
              margin="normal"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSearch();
                }
              }} // Search on Enter key
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton type="submit" edge="end" aria-label="search">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {filteredSuggestions.length > 0 && (
              <Paper className="suggestions-container" elevation={3}>
                <List>
                  {filteredSuggestions.map((suggestion, index) => (
                    <ListItem
                      button
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion}
                    </ListItem>
                  ))}
                </List>
              </Paper>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
