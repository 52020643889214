import i18n from "i18next";
import { initReactI18next} from "react-i18next";



import enHome from "../../src/locales/en/homedata.json";
import taHome from "../../src/locales/ta/homedata.json";
import enHeader from "../../src/locales/en/header.json";
import taHeader from "../../src/locales/ta/header.json"


i18n.use(initReactI18next).init({
     Resources:{
      en: {
         home: enHome,
         header: enHeader,
         // footer: enFooter,
       },
       ta: {
         home: taHome,
         header: taHeader,
         // footer: taFooter,
       },
     },
     fallbackLng:'en',
     interpolation: {
        escapevalue:false
     }
})
export default i18n