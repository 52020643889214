import React, { useState } from "react";
import "../../sass/shortcode/AdvertisementBot.scss";
import Spacing from "../Spacing";
import addimg1 from "../../images/diet/kidneyneeds_awareness_award_2025_Patientcaregivers_new.jpg"
import { Link } from "react-router-dom";

const AdvertisementBot = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="advertisement-bot">
      <button className="close-btn" onClick={handleClose}>
        ✖
      </button>
              <Spacing lg="600" md="15" />
      
      <img
        src={addimg1} // Replace with your ad image URL
        alt="Advertisement"
        className="ad-image"
      />
      <div className="ad-text">
       <Link to="/Events">
       <h4>WORLD KIDNEY DAY EVENTS March 13, 2025</h4>
       </Link>
        {/* <p>
        LunaKIDNEY: One capsule, better kidney health. Simple, effective, revolutionary!</p> */}
      </div>
    </div>
  );
};

export default AdvertisementBot;
