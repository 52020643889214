import React from "react";

import EventsPost from "../../Post/Eventpoststyle";

export default function EventSectionStyle1({ data }) {
  return (
    <div className="container">
      <div className="row cs_row_gap_50">
        {data?.map((item, index) => (
          <div className="col-xl-4 col-md-6" key={index}>
            <EventsPost {...item} />
          </div>
        ))}
      </div>
      {/* <Spacing md="110" lg="70" /> */}
      {/* <Pagination /> */}
    </div>
  );
}
