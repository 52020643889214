import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Pages/Home";
// import About from "./components/Pages/About";
import Doctors from "./components/Pages/Doctors";
import Blog from "./components/Pages/Blog";
import Appointments from "./components/Pages/Appointments";
import BlogDetails from "./components/Pages/BlogDetails";
import DoctorDetails from "./components/Pages/DoctorDetails";
import Contact from "./components/Pages/Contact";
import { useEffect } from "react";
import ErrorPage from "./components/Pages/ErrorPage";
import HealthyKidneys from "./pages/KidneyEducation/HealthyKidneys";
import AboutKidneyDiseases from "./pages/KidneyEducation/AboutKidneyDiseases";
import Dialysis from "./pages/KidneyEducation/Dialysis";
import Transplant from "./pages/KidneyEducation/Transplant";
import KidneyBiopsy from "./pages/KidneyEducation/KidneyBiopsy";
import AVFistula from "./pages/KidneyEducation/AVFistula";
import Catheters from "./pages/KidneyEducation/Catheters";
import Medicines from "./pages/KidneyEducation/Medicines";
import KidneyCatheters from "./pages/OurServices/Catheters";
import DialysisAccessories from "./pages/OurServices/DialysisAccessories";
import KidneyBiopsyGun from "./pages/OurServices/KidneyBiopsyGun";
import LabTestService from "./pages/OurServices/LabTestService";
import ELearningCourse from "./pages/OurServices/ELearningCourse";
import MedicationServices from "./pages/OurServices/Medicines";
import HealthRecords from "./pages/OurServices/HealthRecords";
import KidneyDiet from "./pages/OurServices/KidneyDiet";
import { CounsellingNew } from "./pages/OurServices/Counselling/CounsellingNew";
import CathetersBlogDetailsPage from "./pages/OurServices/Catheters/Blogs/BlogDetails";
import DialysisAccessoriesBlogDetails from "./pages/OurServices/DialysisAccessories/Blogs/BlogDetails";
import KidneyBiopsyBlogDetails from "./pages/OurServices/KidneyBiopsyGun/Blogs/BlogDetails";
import MedicinesBlogDetails from "./pages/OurServices/Medicines/Blogs/BlogDetails";
import HomeBlogDetails from "./pages/HomeBlogs/BlogDetails";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Faq from "./components/Pages/Faq";
import Download from "./components/Pages/Download";
import Research from "./components/Pages/ResearchConnectEnquiry";
// import Diet from "./pages/Diet";
// import About from "./components/Pages/Aboutus";
// import Findcenterresult from "./components/Pages/FindCenterResult";
// import NewsAnnouncements from "./components/Pages/NewsAnnouncements";
// import Newsannouncements1 from "./components/Pages/AnnouncementPage1";
// import NutrientBreakdown from "./pages/Diet";
// import Diet from "./pages/Diet";
// import NewDiet from "./pages/Diet/NewDiet";
// import SubmitNextPage from "./pages/Diet/SubmitNextPage";
// import PersonalizedDietChart from "./pages/Diet/PersonalizedDietChart";
import Login from "./pages/Login/Login";
import SignIn from "./pages/Login/SignIn";
// import SocialMedia from "./components/Pages/SocialMedia";
// import Events from "./components/Pages/Blog";
// import Eventpage from "./components/Pages/Events";
import EventDetails from "./components/Pages/EventsDetails";
// import About from "./components/Pages/Aboutus";
// import NewsLaunch from "./components/Pages/Newlaunch";
import Diet from "./pages/Diet/Diet";
import NewAbouts from "./components/Pages/NewAbout";
// import EventLaunch from "./components/Pages/EventLaunch";
import Eventpage from "./components/Pages/Events";
import LunaKIDNEY from "./components/Pages/AnnouncementPage1";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/news/:id" element={<Home />} />

        <Route path="/login" element={<Login />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/ResearchConnectEnquiry" element={<Research />} />
        <Route path="kidney-education" element={<HealthyKidneys />} />
        <Route
          path="kidney-education/healthy-kidneys"
          element={<HealthyKidneys />}
        />
        <Route
          path="kidney-education/kidney-diseases"
          element={<AboutKidneyDiseases />}
        />
        <Route path="kidney-education/dialysis" element={<Dialysis />} />
        <Route path="kidney-education/transplant" element={<Transplant />} />
        <Route
          path="kidney-education/kidney-biopsy"
          element={<KidneyBiopsy />}
        />
        <Route path="kidney-education/av-fistula" element={<AVFistula />} />
        <Route path="kidney-education/catheters" element={<Catheters />} />
        <Route path="kidney-education/medicines" element={<Medicines />} />
        {/* 
        <Route path="our-services/counselling" element={<Counselling />} /> */}
        <Route path="our-services/counselling" element={<CounsellingNew />} />
        <Route path="our-services/medicines" element={<MedicationServices />} />
        <Route path="our-services/catheters" element={<KidneyCatheters />} />
        <Route
          path="our-services/dialysis-accessories"
          element={<DialysisAccessories />}
        />
        <Route
          path="our-services/kidney-biopsy-gun"
          element={<KidneyBiopsyGun />}
        />
        <Route
          path="our-services/lab-test-service"
          element={<LabTestService />}
        />
        <Route path="our-services/e-learning" element={<ELearningCourse />} />
        <Route path="our-services/health-records" element={<HealthRecords />} />
        <Route path="our-services/kidney-diet" element={<KidneyDiet />} />

        <Route path="home/blog/:blogId" element={<HomeBlogDetails />} />
        <Route
          path="medicine/blog/:blogId"
          element={<MedicinesBlogDetails />}
        />
        <Route
          path="catheters/blog/:blogId"
          element={<CathetersBlogDetailsPage />}
        />
        <Route
          path="dialysis-accessories/blog/:blogId"
          element={<DialysisAccessoriesBlogDetails />}
        />
        <Route
          path="kidney-biopsy/blog/:blogId"
          element={<KidneyBiopsyBlogDetails />}
        />

        <Route path="diet" element={<Diet />} />


        {/* <Route path="/findacenter" element={<Doctors />} />
        <Route path="/Searching-Results" element={<Findcenterresult />} /> */}
        <Route path="about" element={<NewAbouts />} />
        <Route path="FAQ" element={<Faq />} />
        {/* <Route path="/News-Announcements" element={<NewsAnnouncements />} /> */}
        <Route path="/LunaKIDNEY" element={<LunaKIDNEY />} />
        {/* <Route path="/luna-kidney" element={<Newsannouncements1 />} /> */}

        <Route path="Download" element={<Download />} />
        <Route path="centers" element={<Doctors />} />
        <Route path="centers/:centerId" element={<DoctorDetails />} />
        <Route path="/Events" element={<Eventpage />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/:blogId" element={<BlogDetails />} />
        <Route path="appointments" element={<Appointments />} />
        <Route path="/Events-Details" element={<EventDetails/>} />
        {/* <Route path="departments" element={<Departments />} /> */}
        {/* <Route
          path="departments/:departmentId"
          element={<DepartmentDetails />}
        /> */}
        {/* <Route path="pricing-plan" element={<PricingPlan />} />
        <Route path="gallery" element={<Gallery />} /> */}
        {/* <Route path="timetable" element={<Timetable />} /> */}
        <Route path="contact" element={<Contact />} />

        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
      </Route>
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
