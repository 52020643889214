import { createSlice } from "@reduxjs/toolkit";

const profieSlice = createSlice({
    name: "profile",
    initialState: { profileLink: undefined },
    reducers: {
        setProfileLink: (state, action) => {
            state.profileLink = action.payload;
        },
        clearProfileLink: (state) => {
            state.profileLink = undefined; 
        },
    },
});

export const { setProfileLink, clearProfileLink } = profieSlice.actions;

export default profieSlice.reducer;