import React from "react";
import Spacing from "../../Spacing";
import SectionHeading from "../../SectionHeading";
import Team from "../../Team";
import imgUrl from "../../../images/diet/neelam-ceo.jpg";
import imgUrl2 from "../../../images/diet/Kidneyneeds-first-team.jpg"
// import { useTranslation } from 'react-i18next';

export default function TeamSection({ sectionTitle, sectionTitleUp, data }) {
  // const { t } = useTranslation(["aboutKidneyDiseases", "common"]);

  return (
    <div className="container">
      <SectionHeading title={sectionTitle} titleUp={sectionTitleUp} center />
      <Spacing md="50" lg="50" />
      
 
      <div className="row gy-3">
        {data?.map((item, index) => (
          <div className="col-lg-4" key={index}>
            <Team {...item} />
          </div>
        ))}
      </div>
       {/* <Spacing md="72" lg="50" /> */}
      <div className="About_founder_container container">
        <div className="About_founder_container1 About_container_border">
          <div className="cs_team cs_style_1 text-center cs_radius_20 cs_type_1 About_container_border ">
            <div className="cs_member_img ">
              <img loading="lazy" src={imgUrl} alt="name" />
            </div>
            <div className="cs_team_meta ">
              <div>
                <h3 className="cs_member_name cs_semibold cs_fs_40">
                  Ms. Neelam Mani
                </h3>
                <p className="cs_member_designation cs_fs_20 cs_heading_color">
                 Founder & Director
                </p>
                <p className="cs_member_description ">
                From a daughter’s fight for her father’s treatment to creating KidneyNeeds—a platform ensuring no kidney patient is left without guidance, care, or affordability.
                </p>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="About_founder_container2">
          <div className="About_founder_container2_in About_container_border">
            <blockquote>
              <h3 className="cs_member_name cs_semibold cs_fs_40">
              A Message from Our Founder

              </h3>
              <p className="cs_member_description cs_hero_subtitle cs_fs_18">
             <p className="center cs_member_description cs_hero_subtitle cs_fs_18"> "Being a patient is difficult. But hope should never be missing from any prescription."</p>
             <p className="cs_member_description cs_hero_subtitle cs_fs_18">When my father was diagnosed with kidney disease, I realized that medical treatment alone is not enough. Patients struggle with understanding their medications, affording treatment, and finding the right support.</p>
             <p className="cs_member_description cs_hero_subtitle cs_fs_18">I saw families suffering in silence—confused, overwhelmed, and financially drained. KidneyNeeds was born out of that struggle—to ensure no patient faces kidney disease alone.</p>
             <p className="cs_member_description cs_hero_subtitle cs_fs_18"> We are here to guide, support, and empower you.</p>
             <p className="cs_member_description cs_hero_subtitle cs_fs_18">Whether it’s medicines, dialysis support, or dietary counseling—KidneyNeeds is your trusted partner in this journey.</p>
              </p>
            </blockquote>
          </div>
        </div>
      </div>
      <Spacing md="50" lg="50" />
      <div className="About_founder_container container">
        <div className="About_founder_container1 About_container_border">
          <div className="cs_team cs_style_1 text-center cs_radius_20 cs_type_1 About_container_border ">
            <div className="cs_member_img ">
            <img loading="lazy" src={imgUrl2} alt="name" />
            </div>
            <div className="cs_team_meta ">
              <div>
                <h3 className="cs_member_name cs_semibold cs_fs_40">
                First Team
                </h3>
                {/* <p className="cs_member_designation cs_fs_20 cs_heading_color">
                 Founder & Director
                </p> */}
                <p className="cs_member_description ">
                A dedicated, women-led team empowering kidney patients with expert care, support, and compassion.               
                </p>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="About_founder_container2">
          <div className="About_founder_container2_in About_container_border">
            <blockquote>
              <h3 className="cs_member_name cs_semibold cs_fs_40 m-0">
              Growing with Purpose
              </h3>
              <h3 className="cs_member_name cs_semibold cs_fs_32">
              From Two Fighters to a Strong Team
              </h3>
              <p className="cs_member_description cs_hero_subtitle cs_fs_18">
                <p  className="cs_member_description cs_hero_subtitle cs_fs_18 ">Babita and Poorvi were our first warriors, proving that KidneyNeeds isn’t just about treatment—it’s about empowerment.</p>
               
               <p className="center cs_member_description cs_hero_subtitle cs_fs_18 m-0">"From struggling to get dialysis for her father to helping hundreds of patients, Babita ensures no one is left without care."</p>
               <p className="text-right">- Babita Singh</p>
               <p className="center cs_member_description cs_hero_subtitle cs_fs_18 m-0">"Having witnessed dialysis and transplant struggles firsthand, Poorvi now guides other families with compassion."</p>
               <p className="text-right">- Poorvi Yadav</p>
              </p>
            </blockquote>
          </div>
        </div>
      </div>


      {/* <div className="About_founder_container container">
      
        <div className="About_founder_container">
          <div className="About_founder_container2_in About_container_border">
            <blockquote>
              <h3 className="cs_member_name cs_semibold cs_fs_40">
              Growing with Purpose: From Two Fighters to a Strong Team
              </h3>
              <p className="cs_member_description cs_hero_subtitle cs_fs_18">
                <p  className="cs_member_description cs_hero_subtitle cs_fs_18 ">Babita and Poorvi were our first warriors, proving that KidneyNeeds isn’t just about treatment—it’s about empowerment.</p>
               
               <p className="center cs_member_description cs_hero_subtitle cs_fs_18 m-0">"From struggling to get dialysis for her father to helping hundreds of patients, Babita ensures no one is left without care."</p>
               <p className="text-right">- Babita Singh</p>
               <p className="center cs_member_description cs_hero_subtitle cs_fs_18 m-0">"Having witnessed dialysis and transplant struggles firsthand, Poorvi now guides other families with compassion."</p>
               <p className="text-right">- Poorvi Yadav</p>
              </p>
            </blockquote>
          </div>
        </div>
        <div className="About_founder_container11 About_container_border">
          <div className="cs_team cs_style_1 text-center cs_radius_20 cs_type_1">
            <div className="cs_member_img">
              <img loading="lazy" src={imgUrl2} alt="name" />
            </div>
            <div className="cs_team_meta">
              <div>
                <h3 className="cs_member_name cs_semibold cs_fs_40">
                   First Team
                </h3>
                <p className="cs_member_designation cs_fs_20 cs_heading_color">
                  Team
                </p>
                <p className="cs_member_description ">
                A dedicated, women-led team empowering kidney patients with expert care, support, and compassion.                </p>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div> */}
     
    </div>
  );
}
