import React from "react";
import Section from "../Section";
// import BlogSectionStyle2 from "../Section/BlogSection/BlogSectionStyle2";
import Breadcrumb from "../Breadcrumb";
import { pageTitle } from "../../helpers/PageTitle";
// import { allBlogListDatas } from "../../constants/common-contents";
import EventSectionStyle1 from "../Section/BlogSection/EventSectionSection1";
import { EventsDatas } from "./eventsdada";
import { useTranslation } from "react-i18next";
// import DownloadPoster from "./DownloadEventPoster";

export default function Eventpage() {
  pageTitle("Events");
 const { t } = useTranslation(["events"]);
   const {
    Eventsdada1
    } = EventsDatas();
  
  return (
    <>
      <Section topMd={170} bottomMd={40} bottomLg={40}>
        <Breadcrumb title={t("title")} />
      </Section>
      <Section bottomMd={20} bottomLg={10} bottomXl={20}>
        <EventSectionStyle1 data={Eventsdada1} />
      </Section>
      {/* <Section bottomMd={20} bottomLg={10} bottomXl={60}>
      <DownloadPoster/>
      </Section> */}
     
    </>
  );
}
