import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";

export default function ProductsPost({
  title,
  subTitle = "",
  thumbUrl,
  date,
  btnText,
  href,
  socialShare,
  variant,
  gradient,
  filename,
  pdfurl,
  dowbtnText,
  btnArrowUrl,
}) {
  return (
    <div
      style={{
        backgroundImage: gradient
          ? `linear-gradient(${gradient?.a}, ${gradient?.b})`
          : "",
      }}
      className={`cs_post cs_style_1 ${variant ?? ""}`}
    >
      {/* Thumbnail Section */}
      {thumbUrl && (
        <Link to={href} className="cs_post_thum cs_view_mouse">
          <img loading="lazy" src={thumbUrl} alt={title} />
        </Link>
      )}

      <div className="cs_post_info_product">
        <div>
          {/* Social Share Icons */}
          {socialShare && (
            <div className="cs_post_meta">
              <div className="cs_post_social">
                <Link to="/" className="cs_center rounded-circle">
                  <Icon icon="fa-brands:linkedin-in" />
                </Link>
                <Link to="/" className="cs_center rounded-circle">
                  <Icon icon="fa-brands:facebook-f" />
                </Link>
                <Link to="/" className="cs_center rounded-circle">
                  <Icon icon="fa-brands:twitter" />
                </Link>
              </div>
            </div>
          )}

          {/* Title and Subtitle */}
          <h2 className=" cs_medium  cs_fs_24" style={{ margin: 0 }}>
            { title}
          </h2>
         {/* <div><p className="m-0 productcolor">{subTitle}</p></div> */}
        <span className="cs_member_designation cs_fs_18 mr_top cs_heading_color">{subTitle}</span>
        </div>

        {/* Button or Download Link */}
        {btnText && !pdfurl && (
         <div className="button-containe">
         <Link to={href} className="custom-button">
           {btnText}
         </Link>
       </div>
       
        )}
        {pdfurl && dowbtnText && (
          <a href={pdfurl} download={filename} className="cs_download_btn">
            {dowbtnText}
            {btnArrowUrl && (
              <img
                src={btnArrowUrl}
                alt="Download arrow"
                className="cs_download_icon"
              />
            )}
          </a>
        )}
      </div>
    </div>
  );
}
