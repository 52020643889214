import React, { useEffect, useState } from "react";
import ContactInfoWidget from "../../Widget/ContactInfoWidget";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import Newsletter from "../../Widget/Newsletter";
import { CustomToastContainer } from "../../../helpers/toastHelper";
// import { t } from "i18next";
import { useTranslation } from "react-i18next";
// import { useTranslation } from "react-i18next";


const supportInfos = [
  { title: "Professionals", href: "/" },
  { title: "FAQs", href: "/faq" },

  { title: "About Us", href: "/about" },
  // { title: "New launch", href: "/Newlaunch" },
  // { title: "Download", href: "/Download" },
  { title: "Blogs", href: "blog" },
  // { title: "Events", href: "/Events" },
  { title: "LunaKIDNEY", href: "/LunaKIDNEY" },
  // { title: "Social Media", href: "/Social-Media" },
  { title: "Contact Us", href: "/contact" },
];

const termsInfos = [
  { title: "Privacy Policy", href: "/privacy-policy" },
  { title: "Terms and Conditions", href: "/terms-and-conditions" },
];

const kidneyEducationMenus = [
  { title: "Healthy Kidneys", href: "kidney-education/healthy-kidneys" },
  {
    title: "Learn About Kidney Diseases",
    href: "kidney-education/kidney-diseases",
  },
  { title: "Dialysis", href: "kidney-education/dialysis" },
  { title: "Transplant", href: "kidney-education/transplant" },
  { title: "Kidney Biopsy", href: "kidney-education/kidney-biopsy" },
  { title: "AV Fistula", href: "kidney-education/av-fistula" },
  { title: "Catheters", href: "kidney-education/catheters" },
  { title: "Medicines", href: "kidney-education/medicines" },
];

const ourServicesMenus = [
  { title: "Counselling", href: "our-services/counselling" },
  { title: "Lab Test", href: "our-services/lab-tests" },
  { title: "Catheters", href: "our-services/catheters" },
  { title: "Dialysis Accessories", href: "our-services/dialysis-accessories" },
  { title: "Kidney Biopsy Gun", href: "our-services/kidney-biopsy-gun" },
  { title: "E-Learning Course For Patients", href: "our-services/e-learning" },
  { title: "Health Records", href: "our-services/health-records" },
  { title: "Kidney Diet", href: "our-services/kidney-diet" },
];

const Footer = () => {
  const { t } = useTranslation(["footer"]);

  const [activeBg, setActiveBg] = useState("");

  const { pathname } = useLocation();

  useEffect(() => {
    const pathSegments = pathname
      .split("/")
      .filter((segment) => segment !== "");

    if (pathSegments.length > 0 && pathSegments[0] === "kidney-education")
      setActiveBg("linear-gradient(rgb(247, 146, 38), rgb(240, 84, 69))");
    else if (pathSegments.length > 0 && pathSegments[0] === "our-services")
      setActiveBg("linear-gradient(rgb(43, 170, 226), rgb(57, 87, 166))");
    else setActiveBg("linear-gradient(rgb(64, 191, 187), rgb(7, 146, 71))");
  }, [pathname]);

  return (
    <footer className="cs_custom_footer cs_white_color">
      <CustomToastContainer />
      <div
        className="cs_footer_top_line"
        style={{ backgroundImage: `${activeBg}` }}
      />
      <div className="cs_footer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="cs_footer_item cs_brand_section">
                <h5>
                  {t("footer.heading")}
                </h5>
                <ContactInfoWidget iconColor="#307bc4" />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="cs_footer_item cs_menu_section">
                <h5> {t("footer.KidneyEducation")}</h5>
                <ul className="cs_menu_widget cs_mp0">
                  {kidneyEducationMenus?.map((item, index) => (
                    <li key={index}>
                      <Link to={item.href}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="cs_footer_item cs_menu_section">
                <h5>{t("footer.OurServices")} </h5>
                <ul className="cs_menu_widget cs_mp0">
                  {ourServicesMenus?.map((item, index) => (
                    <li key={index}>
                      <Link to={item.href}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="cs_footer_item cs_menu_section">
                <h5>{t("footer.GetSupport")} </h5>
                <ul className="cs_menu_widget cs_mp0">
                  {supportInfos?.map((item, index) => (
                    <li key={index}>
                      <Link to={item.href}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="cs_footer_item cs_brand_follow_us_section">
                <div className="cs_follow_us_section">
                  <h5>{t("footer.Follow")} </h5>
                  <div className="cs_social_links">
                    <Link to="/">
                      <Icon icon="fa-brands:facebook-f" />
                    </Link>
                    <Link to="/">
                      <Icon icon="fa-brands:youtube" />
                    </Link>
                    <Link to="/">
                      <Icon icon="fa-brands:linkedin-in" />
                    </Link>
                    <Link to="/">
                      <Icon icon="fa-brands:twitter" />
                    </Link>
                    <Link to="/">
                      <Icon icon="fa-brands:instagram" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2"></div>
            <div className="col-lg-4">
              <div className="cs_menu_section">
                <Newsletter
                  title={t("footer.Newsletter.title")}
                  subTitle={t("footer.Newsletter.subTitle")}
                  customClass="cs_custom_footer_newsletter"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cs_footer_bottom">
        <div className="container">
          <div className="cs_footer_bottom_in">
            <div className="cs_terms_info">
              <ul className="cs_menu_widget cs_mp0">
                {termsInfos?.map((item, index) => (
                  <li key={index}>
                    <Link to={item.href}>{item.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="cs_copyright">
              {t("footer.copyright")}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
