import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import VideoModal from "../../components/VideoModal";

export default function HeroStyle2({
  title,
  title2,
  subTitle,
  bgUrl,
  imgUrl,
  videoBtnText,
  videoUrl,
  funfactList,
  infoNavList = [],
  btnText,
  btnUrl,
  customClasses = "",
}) {
  return (
    <section
      className={`cs_hero cs_style_2 cs_bg_filed ${customClasses}`}
      style={{ backgroundImage: `url(${bgUrl})` }}
    >
      <div className="container">
      <div className="cs_hero_text" style={{ marginTop: "25px" }}> 
          <h1 className="cs_hero_title cs_white_color cs_fs_84">
            {parse(title)}
          </h1>
          <h1 className="cs_hero_title2  cs_fs_84" >
            {parse(title2)}
          </h1>
          <div className="cs_hero_text_in">
            {btnText && (
              <div className="cs_hero_btn cs_white_color">
                <Link to={btnUrl} className="cs_text_btn">
                  {btnText}
                </Link>
              </div>
            )}
            <p className="cs_hero_subtitle cs_fs_20 cs_white_color">{subTitle}</p>
          </div>
        </div>
        <img loading="lazy" src={imgUrl} alt="Hero" className="cs_hero_patents" />
      </div>
      <div className="container " >
        <div className="cs_hero_bottom">
          <div className="cs_hero_bottom_left">
            <div className="cs_hero_btn_wrap" >
              <VideoModal
                videoUrl={videoUrl}
                videoBtnText={videoBtnText}
                variant="cs_white_color"
              />
            </div>
          </div>
          <div className="cs_hero_bottom_right">
            {funfactList?.length > 0 && (
              <div className="cs_hero_funfact text-center">
                {funfactList?.map((item, index) => (
                  <div className="cs_hero_funfact_col" key={index}>
                    <h3 className="cs_white_color cs_fs_72">{item.number}</h3>
                    <p className="cs_white_color mb-0">{item.title}</p>
                  </div>
                ))}
              </div>
            )}
            {infoNavList?.length > 0 && (
              <Link href="/">
                <div className="cs_hero_funfact text-center">
                  {infoNavList?.map((item, index) => (
                    <div
                      className="cs_hero_funfact_col cs_kidney_home_page"
                      key={index}
                    >
                      <div className="cs_nav_icon cs_center rounded-circle cs_white_bg">
                        <img loading="lazy" src={item.iconUrl} alt="Icon" />
                      </div>
                      <h3 className="cs_white_color cs_fs_48">{item.title}</h3>
                      {item.subTitle && (
                        <p className="cs_white_color mb-0">{item.subTitle}</p>
                      )}
                    </div>
                  ))}
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
