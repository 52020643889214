import React from "react";
import "../../sass/shortcode/Login.scss";
import Spacing from "../../components/Spacing";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import btnArrowUrl from "../../images/icons/arrow_white.svg";

function SignIn() {
  const navigate = useNavigate();
  return (
    <>
      <div className="container">
        <Spacing lg="600" md="90" />

        <div className="container_login">
          <div className="left_container"></div>
          <div className="right_container">
            <div className="center_div">
              <h1>Sign In</h1>
              <p className="wel">Welcome Back</p>
              <form className="form5">
                <div className="input-group">
                  <input
                    type="name"
                    id="username"
                    name="username"
                    required
                    placeholder="Enter Your Name"
                    //   value={mobileNumber}
                    //   onChange={(e) => setMobileNumber(e.target.value)}
                  />
                </div>
                <div className="input-group">
                  <input
                    type="number"
                    id="username"
                    name="username"
                    required
                    placeholder="Mobile Number"
                    //   value={mobileNumber}
                    //   onChange={(e) => setMobileNumber(e.target.value)}
                  />
                </div>
                <div className="input-group">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder=" Set Your Password"
                    required
                    //   value={password}
                    //   onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="options">
                  <label>
                    <input
                      type="checkbox"
                      name="remember"
                      className="remember"
                    />{" "}
                    Remember Me
                  </label>
                  <p className="forgot_password1">Forgot Password?</p>
                </div>
                <button className="cs_btn cs_style_1">
                  <span>Login</span>
                  <i>
                    <img loading="lazy" src={btnArrowUrl} alt="Icon" />
                    <img loading="lazy" src={btnArrowUrl} alt="Icon" />
                  </i>
                </button>
                <div className="account">
                  <Spacing lg="600" md="10" />
                  <Link to="/login">
                    Already have an account? Click here to login
                  </Link>
                  {/* <p className="Dont">Don't have an account? Create a free account</p> */}
                </div>
              </form>
              <div className="Or">
                <p>Or</p>
              </div>
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  console.log(credentialResponse);
                  navigate("/PersonalizedDietChart");
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
              {/* {loginStatus === 'success' && <p>Login successful! Redirecting...</p>}
            {loginStatus === 'failure' && <p className="error-message">Login failed. Please try again.</p>}
            {loginStatus === 'error' && <p className="error-message">An error occurred. Please try again later.</p>} */}
            </div>
          </div>
        </div>

        <Spacing lg="600" md="50" />
      </div>
    </>
  );
}

export default SignIn;
