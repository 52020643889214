// // import { Icon } from "@iconify/react";
// import React from "react";
// import { Link } from "react-router-dom";
// import "../../sass/shortcode/eventpost.scss";
// // import thumbUrl1 from "../../images/diet/post1.png"


// export default function EventsDetailsPost({
//   title,
//   subTitle = "",
//   thumbUrl,
//   date,
//   btnText,
//   href,
//   socialShare,
//   variant,
//   gradient,
//   filename,
//   pdfurl,
//   dowbtnText,
//   btnArrowUrl,
//   subtitle1,
//   subtitle11,
//   subtitle12,
//   subtitle13,
//   subtitle2,
//   subtitle3,
//   subtitle31,
//   subtitle32,
//   subtitle33,
//   subtitle4,
//   subtitle5
// }) {

//     // const whatsappMessage = encodeURIComponent("I want to register for World Kidney Day events 2025.");

//   return (
//     <div className="EventDetails_container">
//         <div className="EventDetails_post">
//             {/* <h2 className="center">{title}</h2> */}
//             <div className="img_containerev center">
                
//              <img  src={thumbUrl} alt={title} />
                       
//   <div className="registration-inquiry-center">
//   <div className="registration-inquiry">
//       <h3 className="side-heading">Registration Enquiry</h3>

//       {/* Call Button */}
//       <div className="contact-item">
//         <Link to="tel:+919025585770">
//           <i className="fa-solid fa-phone icon"></i>
//           <span>Phone Call: +91 91116 06162</span>
//         </Link>
//       </div>

//       {/* WhatsApp Chat */}
//       <div className="contact-item">
//       <Link 
//   to={`https://wa.me/919111606162?text=${encodeURIComponent("Hi, I want to participate in World Kidney Day Event 2025.")}`} 
//   target="_blank"
// >
//   <i className="fa-brands fa-whatsapp icon whatsapp"></i>
//   <span>WhatsApp: Chat Now</span>
// </Link>

//       </div>

//       {/* Email */}
//       <div className="contact-item">
//         <Link to="mailto:support@kidneyneeds.com">
//           <i className="fa-solid fa-envelope icon email"></i>
//           <span>Email: support@kidneyneeds.com</span>
//         </Link>
//       </div>
//     </div>

//   </div>
//   <div>
//   <button>download </button>
//   </div>
//             </div>
//             {/* <div className="spaceing"></div> */}

//         </div>

//         <div className="EventDetails_content">
//           <div className="contentsev">
//           <div class="container">
//         <h3>KidneyNeeds Awareness Award 2025</h3>
//         <h4>{title}</h4>
        
//         <div class="prizes">
//             <h5>{subtitle1}</h5>
//             <p>{subtitle11}</p>
//             <p>{subtitle12}</p>
//             <p>{subtitle13}</p>
//         </div>

//         <p><strong>{subtitle2}</strong></p>

//         <h5>{subtitle3}</h5>
//         <ul>
//             <li>{subtitle31}</li>
//             <li>{subtitle32}</li>
//             <li>{subtitle33}</li>
//         </ul>

//         <p><strong>{subtitle4}</strong></p>
//         <p><strong>{subtitle5}</strong></p>
        
//         <h5>📢 Spread Awareness</h5>
//         <ul>
//             <li>🏙️ Spread awareness in your city & village</li>
//             <li>🌟 Get Your Talent Acknowledged at a National Level</li>
//             <li>🎨 Showcase Your Creativity & Social Responsibility</li>
//         </ul>
//         <h5>📅 Submission Dates</h5>
//         <p>📝 Name Submission: <strong>13th March 2025</strong></p>
//         <p>📩 Final Submission: <strong>13th April 2025</strong></p>

//         <h5>🏅 Win Exciting Rewards & Recognition!</h5>

//         <div class="contact">
//             <p>📩 Submit via WhatsApp or Email</p>
//             {/* <p>📞 Contact: <strong>+91 811 60 61 62</strong></p> */}
//         </div>
//     </div>
  



//              </div>

    
//         </div>
      
     
//     </div>
//   );
// }
import React from "react";
import { Link } from "react-router-dom";
import "../../sass/shortcode/eventpost.scss";
import Spacing from "../Spacing";


export default function EventsDetailsPost({
  title,
  subTitle = "",
  thumbUrl,
  date,
  btnText,
  href,
  socialShare,
  variant,
  gradient,
  filename,
  pdfurl,
  dowbtnText,
  btnArrowUrl,
  subtitle1,
  subtitle11,
  subtitle12,
  subtitle13,
  subtitle2,
  subtitle3,
  subtitle31,
  subtitle32,
  subtitle33,
  subtitle4,
  subtitle5,
}) {
  // const handleDownload = () => {
  //   const link = document.createElement("a");
  //   link.href = pdfurl;
  //   link.download = filename || "document.pdf";
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  return (
    <div className="EventDetails_container">
      <div className="EventDetails_post">
        <div className="img_containerev center">
          <img src={thumbUrl} alt={title} />

          <div className="registration-inquiry-center">
            <div className="registration-inquiry">
              <h3 className="side-heading">Registration Enquiry</h3>

              {/* Call Button */}
              <div className="contact-item">
                <Link to="tel:+919025585770">
                  <i className="fa-solid fa-phone icon"></i>
                  <span>Phone Call: +91 91116 06162</span>
                </Link>
              </div>

              {/* WhatsApp Chat */}
              <div className="contact-item">
                <Link
                  to={`https://wa.me/919111606162?text=${encodeURIComponent(
                    "Hi, I want to participate in World Kidney Day Event 2025."
                  )}`}
                  target="_blank"
                >
                  <i className="fa-brands fa-whatsapp icon whatsapp"></i>
                  <span>WhatsApp: Chat Now</span>
                </Link>
              </div>

              {/* Email */}
              <div className="contact-item">
                <Link to="mailto:support@kidneyneeds.com">
                  <i className="fa-solid fa-envelope icon email"></i>
                  <span>Email: support@kidneyneeds.com</span>
                </Link>
              </div>
            </div>
          </div>

          {/* Download Button */}
         {/* Download Button */}
         <div className="download-butto" style={{ display: "flex", justifyContent: "center", margin: "0" }}>
  <a href={pdfurl} download={filename || "document.pdf"} className="cs_download_btn">
    {dowbtnText || "Download PDF"}
    {btnArrowUrl && <img src={btnArrowUrl} alt="Arrow" className="cs_download_icon" />}
  </a>
</div>
 <Spacing md="20" lg="100" />


        </div>
      </div>

      <div className="EventDetails_content">
        <div className="contentsev">
          <div className="container">
            <h3>KidneyNeeds Awareness Award 2025</h3>
            <h4>{title}</h4>

            <div className="prizes">
              <h5>{subtitle1}</h5>
              <p>{subtitle11}</p>
              <p>{subtitle12}</p>
              <p>{subtitle13}</p>
            </div>

            <p><strong>{subtitle2}</strong></p>

            <h5>{subtitle3}</h5>
            <p>{subtitle31}</p>
              <p>{subtitle32}</p>
              <p>{subtitle33}</p>
            {/* <ul>
              <li>{subtitle31}</li>
              <li>{subtitle32}</li>
              <li>{subtitle33}</li>
            </ul> */}

            <p><strong>{subtitle4}</strong></p>
            <p><strong>{subtitle5}</strong></p>

            <h5>📢 Spread Awareness</h5>
            <ul>
              <li>🏙️ Spread awareness in your city & village</li>
              <li>🌟 Get Your Talent Acknowledged at a National Level</li>
              <li>🎨 Showcase Your Creativity & Social Responsibility</li>
            </ul>

            <h5>📅 Submission Dates</h5>
            <p>📝 Registration Start: <strong>13th March 2025</strong></p>
            <p>📩 Final Submission: <strong>13th April 2025</strong></p>

            <h5>🏅 Win Exciting Rewards & Recognition!</h5>

            <div className="contact">
              <p>📩 Submit via WhatsApp or Email</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
