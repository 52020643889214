import React from "react";

import EventsDetailsPost from "../../Post/EventDetailsPost";

export default function EventDetailsSectionStyle({ data }) {
  return (
    <div className="container">
      <div className="row cs_row_gap_50">
        {data?.map((item, index) => (
          <div className="" key={index}>
            <EventsDetailsPost {...item} />
          </div>
        ))}
      </div>
      {/* <Spacing md="110" lg="70" /> */}
      {/* <Pagination /> */}
    </div>
  );
}
