import React from "react";
import { Link } from "react-router-dom";
import "../../sass/shortcode/smallnav.scss";
// import { useTranslation } from "react-i18next";
import "../../Language/i18n";
// import {  useDispatch } from "react-redux"; // Import useDispatch
// import Cookies from "js-cookie";
// import { clearProfileLink } from "./../../services/store/slices/profileSlice"; // Import clearProfileLink

export default function Smallnav() {
  // const [showProfileBar, setShowProfileBar] = useState(false);
  // const [showProfileModal, setShowProfileModal] = useState(false);
  // const [profileName, setProfileName] = useState("");
  // const { i18n } = useTranslation();
  // const profileLink = useSelector((state) => state.profile.profileLink);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   const name = Cookies.get("ProfileName");
  //   if (name) {
  //     setProfileName(name);
  //   }
  // }, []);

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };

  // const handleProfileClick = () => {
  //   setShowProfileModal(!showProfileModal);
  // };

  // const handleLogout = () => {
  //   setShowProfileModal(false);
  //   setProfileName("");
  //   Cookies.remove("ProfileName");
  //   dispatch(clearProfileLink());
  // };

  return (
    <>
      <div className="navbar-one flex1">
        <div className="left flex">
          <div className="email">
            <i className="fa fa-envelope"></i>
            <a href="mailto:support@kidneyneeds.com">support@kidneyneeds.com</a>
          </div>
          <div className="call">
            <i className="fa-solid fa-phone"></i>
            <a href="tel:+919111606162">+91 91116 06162</a>
          </div>
        </div>

        <div className="right flex">
          <div className="oneclick">
            <Link to="/contact">One Click Solution</Link>
          </div>
          {/* <div className="lan_btn">
            <select onChange={(e) => changeLanguage(e.target.value)}>
              <option value="en">English</option>
              <option value="ta">Tamil</option>
              <option value="hi">Hindi</option>
            </select>
          </div> */}
          {/* {profileLink ? (
            <div className="profile-container" onClick={handleProfileClick}>
              <img
                className="profil-picture"
                src={profileLink}
                alt="Profile Pic"
              />
              {showProfileModal && (
                <div className="profile-modal">
                  <div className="profile-details">
                    <p className="heading">Profile details</p>
                    <p>{profileName}</p>
                  </div>
                  <button onClick={handleLogout}>Logout</button>
                </div>
              )}
            </div>
          ) : (
            <div className="logins">
              <Link to="/login">Login</Link>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
}
