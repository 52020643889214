import React from "react";

import Spacing from "../Spacing";
// import IconBoxStyle3 from "../IconBox/IconBoxStyle3";
import SectionHeading from "../SectionHeading";
import IconBoxStyle15 from "../IconBox/IconBoxStyle15";

export default function HistoryProcess({
  sectionTitle,
  sectionTitleUp,
  sectionTitleDown,
  sectionSubTitle,
  sectionSubTitleTans = "",
  data,
}) {
  return (
    <div className="cs_shape_wrap">
      <div className="cs_shape_1 cs_position_2" />
      <div className="container">
        <SectionHeading
          title={sectionTitle}
          titleUp={sectionTitleUp}
          titleDown={sectionTitleDown}
          subTitle={sectionSubTitle}
          subTitleTrans={sectionSubTitleTans}
          center
        />
        <Spacing md="105" lg="50" />
        <div className="cs_iconbox_3_wrap">
          {data?.map((item, index) => (
            <IconBoxStyle15 key={index} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
}
