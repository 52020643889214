import React from "react";
import { useNavigate } from "react-router-dom";

const newsItems = [
    { id: 1, text: "World Kidney Day 2025 Events Announcements :" , link: "/Events"},
    // { id: 2, text: " Express Your Story", link: "//Events" },
    // { id: 3, text: "Influencer Reels" ,link: "/Events"  },
    { id: 4, text: "Drawing Competition" , link: "/Events"},
    { id: 5, text: "Story & Poem Writing Competition" , link: "/Events"},
    { id: 6, text: "Selfie Video" , link: "/Events"},
    { id: 7, text: " Competition for Kidney Patients " , link: "/Events"},
    { id: 8, text: "Live Stream on March 13, 2025" , link: "/Events"},
    
];

const NewsTicker = () => {
    const navigate = useNavigate();
    const heading = "News & Events";
    return (
        <div className="news-container news_line_container">
        <div className="title cs_semibold">{heading}</div>
        <ul>
            {newsItems.map((news) => (
                <li key={news.id} onClick={() => navigate(news.link)}>
                    {news.text}
                </li>
            ))}
        </ul>
    </div>
    );
};

export default NewsTicker;
