import i18n from "../../i18n/i18n";

export const aboutDatas = () => {
 
const OurOutherProductsdata = [
    {
      title: i18n.t("OurOutherProductsdata.product1.title", {
        ns: "about",
      }),
      subTitle: i18n.t("OurOutherProductsdata.product1.subTitle", {
        ns: "about",
      }),
      thumbUrl: "images/home/791x751-01ab.jpg",
    },
    {
      title: i18n.t("OurOutherProductsdata.product2.title", {
        ns: "about",
      }),
      subTitle: i18n.t("OurOutherProductsdata.product2.subTitle", {
        ns: "about",
      }),
      thumbUrl: "images/home/791x751-02ab.jpg",
    },
    {
        title: i18n.t("OurOutherProductsdata.product3.title", {
          ns: "about",
        }),
        subTitle: i18n.t("OurOutherProductsdata.product3.subTitle", {
          ns: "about",
        }),
        thumbUrl: "images/home/791x751-03ab.jpg",
      },{
        title: i18n.t("OurOutherProductsdata.product4.title", {
          ns: "about",
        }),
        subTitle: i18n.t("OurOutherProductsdata.product4.subTitle", {
          ns: "about",
        }),
        thumbUrl: "images/home/791x751-04ab.jpg",
      },
  ];

const OurteamData =[
    {
        imgUrl: 'images/about/neelam-ceo.jpg',
        name: i18n.t("OurteamData.Team1.name", {
            ns: "about",
          }),
        designation: i18n.t("OurteamData.Team1.designation",{
            ns: "about",
          }),
        description: i18n.t("OurteamData.Team1.description",{
            ns: "about",
        }),  
    },
    {
        imgUrl: 'images/about/portfolio_5.jpeg',
        name: i18n.t("OurteamData.Team2.name", {
            ns: "about",
          }),
        designation: i18n.t("OurteamData.Team2.designation",{
            ns: "about",
          }),
        description: i18n.t("OurteamData.Team2.description",{
            ns: "about",
        }),  
    },
    {
        imgUrl: 'images/about/portfolio_5.jpeg',
        name: i18n.t("OurteamData.Team3.name", {
            ns: "about",
          }),
        designation: i18n.t("OurteamData.Team3.designation",{
            ns: "about",
          }),
        description: i18n.t("OurteamData.Team3.description",{
            ns: "about",
        }),  
    }
];
const OurAwarddata =[
    {
        iconUrl: '/images/icons/icon1.svg',
        title: i18n.t("AwardSectiondata.Award1.title",{
            ns: "about",
        }),
    },
    {
        iconUrl: '/images/icons/icon2.svg',
        title: i18n.t("AwardSectiondata.Award2.title",{
            ns: "about",
        }),
    },
    {
        iconUrl: '/images/icons/icon3.svg',
        title: i18n.t("AwardSectiondata.Award3.title",{
            ns: "about",
        }),
    },
    {
        iconUrl: '/images/icons/icon4.svg',
        title: i18n.t("AwardSectiondata.Award4.title",{
            ns: "about",
        }),
    }
];
const OurOutherProductsdata2 = [
    {
      title: i18n.t("OurOutherProductsdata2.product1.title", {
        ns: "about",
      }),
      subTitle: i18n.t("OurOutherProductsdata2.product1.subTitle", {
        ns: "about",
      }),
      thumbUrl: "images/home/791x751-05ab.jpg",
    },
    {
      title: i18n.t("OurOutherProductsdata2.product2.title", {
        ns: "about",
      }),
      subTitle: i18n.t("OurOutherProductsdata2.product2.subTitle", {
        ns: "about",
      }),
      thumbUrl: "images/home/791x751-08ab.jpg",
    },
    {
        title: i18n.t("OurOutherProductsdata2.product3.title", {
          ns: "about",
        }),
        subTitle: i18n.t("OurOutherProductsdata2.product3.subTitle", {
          ns: "about",
        }),
        thumbUrl: "images/home/791x751-06ab.jpg",
      },{
        title: i18n.t("OurOutherProductsdata2.product4.title", {
          ns: "about",
        }),
        subTitle: i18n.t("OurOutherProductsdata2.product4.subTitle", {
          ns: "about",
        }),
        thumbUrl: "images/home/791x751-09ab.jpg",
      },
  ];
  const Ourproductdata = [
    {
        title: i18n.t("Productsdatalist.productdata1.title",{
            ns: "about",
        }),
        subTitle: i18n.t("Productsdatalist.productdata1.subTitle",{
            ns: "about",
        }),
        btnText: i18n.t("Productsdatalist.productdata1.btntext",{
            ns: "about",
        }),
        thumbUrl: "images/about/Kidney-Care-Essential-Kit.png",
        // href: "/kidney-education/kidney-diseases",
        socialShare: false,
    },
    {
        title: i18n.t("Productsdatalist.productdata2.title",{
            ns: "about",
        }),
        subTitle: i18n.t("Productsdatalist.productdata2.subTitle",{
            ns: "about",
        }),
        btnText: i18n.t("Productsdatalist.productdata1.btntext",{
            ns: "about",
        }),
        thumbUrl: "images/about/Dialysis-Comfort-Plus.png",
        // href: "/our-services/counselling",
        socialShare: false,
    },
    {
        title: i18n.t("Productsdatalist.productdata3.title",{
            ns: "about",
        }),
        subTitle: i18n.t("Productsdatalist.productdata3.subTitle",{
            ns: "about",
        }),
        btnText: i18n.t("Productsdatalist.productdata1.btntext",{
            ns: "about",
        }),
        thumbUrl: "images/about/Renal-Diet-Supplements.png",
        // href: "/our-services/counselling",
        socialShare: false,
    },
    // {
    //     title: i18n.t("Productsdatalist.productdata4.title",{
    //         ns: "about",
    //     }),
    //     subTitle: i18n.t("Productsdatalist.productdata4.subTitle",{
    //         ns: "about",
    //     }),
    //     btnText: i18n.t("Productsdatalist.productdata1.btntext",{
    //         ns: "about",
    //     }),
    //     thumbUrl: "images/about/Tablet.png",
      
    //     socialShare: false,
    // }
];


const historydata = [
  {
    title: i18n.t("HistoryData.HistoryData1.title", {
      ns: "about",
    }),
    subTitle: i18n.t(
      "HistoryData.HistoryData1.subTitle",
      {
        ns: "about",
      }
    ),
    iconUrl: "/images/our_services/kidney-biopsy-gun/His1.png",
    number: "01",
  },
  {
    title: i18n.t("HistoryData.HistoryData2.title", {
      ns: "about",
    }),
    subTitle: i18n.t(
      "HistoryData.HistoryData2.subTitle",
      {
        ns: "about",
      }
    ),
    iconUrl: "/images/our_services/kidney-biopsy-gun/His2.png",
    number: "02",
  },
  {
    title: i18n.t("HistoryData.HistoryData3.title", {
      ns: "about",
    }),
    subTitle: i18n.t(
      "HistoryData.HistoryData3.subTitle",
      {
        ns: "about",
      }
    ),
    iconUrl: "/images/our_services/kidney-biopsy-gun/His3.png",
    number: "03",
  },
  {
    title: i18n.t("HistoryData.HistoryData4.title", {
      ns: "about",
    }),
    subTitle: i18n.t(
      "HistoryData.HistoryData4.subTitle",
      {
        ns: "about",
      }
    ),
    iconUrl: "/images/our_services/kidney-biopsy-gun/His4.png",
    number: "04",
  }
  
];



  return {
    OurOutherProductsdata,
    OurteamData,
    OurAwarddata,
    OurOutherProductsdata2,
    Ourproductdata,
    historydata

    
  };
};
