import React from "react";
import Section from "../Section";
// import BlogSectionStyle2 from "../Section/BlogSection/BlogSectionStyle2";
import Breadcrumb from "../Breadcrumb";
import { pageTitle } from "../../helpers/PageTitle";
// import { allBlogListDatas } from "../../constants/common-contents";
// import EventSectionStyle1 from "../Section/BlogSection/EventSectionSection1";
import { EventsDatas } from "./eventsdada";
import { useTranslation } from "react-i18next";
import EventDetailsSectionStyle from "../Section/BlogSection/EventDetailsSection";

export default function EventDetails() {
  pageTitle("Events");
 const { t } = useTranslation(["events"]);
   const {
    Eventsdada12
    } = EventsDatas();
  
  return (
    <>
      <Section topMd={170} bottomMd={40} bottomLg={40}>
        <Breadcrumb title={t("eventtitle")} />
      </Section>
      <Section bottomMd={20} bottomLg={150} bottomXl={60}>
        <EventDetailsSectionStyle data={Eventsdada12} />
      </Section>
    </>
  );
}
