import i18n from "../../i18n/i18n";

export const EventsDatas = () => {
  const Eventsdada1 = [
    {
      title: i18n.t("WorldKidneydayEventsData.Event2.title", {
        ns: "events",
      }),
      subTitle: i18n.t("WorldKidneydayEventsData.Event2.description", {
        ns: "events",
      }),
      thumbUrl: "images/home/kidneyneeds_awareness_award_2025_Dialysis_warriors_new.jpg",
     
      btnText: "Register now! 📞 +91 91116 06162",
       href: "/Events-Details",
       pdfurl: "pdfs/kidneyneeds_awareness_award_2025_Dialysis_warriors.pdf",
       dowbtnText : "Download Poster",
       filename : "kidneyneeds_awareness_award_2025_Dialysis_warriors",
        btnArrowUrl: "images/icons/Download_Arrow.svg"
     
    },
    {
      title: i18n.t("WorldKidneydayEventsData.Event2.title", {
        ns: "events",
      }),
      subTitle: i18n.t("WorldKidneydayEventsData.Event2.description", {
        ns: "events",
      }),
      thumbUrl: "images/home/kidneyneeds_awareness_award_2025_Patientcaregivers_new.jpg",
     
      btnText: "Register now! 📞 +91 91116 06162",
       href: "/Events-Details",
       pdfurl: "pdfs/kidneyneeds_awareness_award_2025_Patientcaregivers.pdf",
       dowbtnText : "Download Poster",
       filename : "kidneyneeds_awareness_award_2025_Patientcaregivers",
        btnArrowUrl: "images/icons/Download_Arrow.svg"
     
    },
    {
      title: i18n.t("WorldKidneydayEventsData.Event1.title", {
        ns: "events",
      }),
      subTitle: i18n.t("WorldKidneydayEventsData.Event1.description", {
        ns: "events",
      }),
      thumbUrl: "images/home/kidneyneeds_awareness_award_2025_Creator_and_Influencers_new.jpg",
     
      btnText: "Register now! 📞 +91 91116 06162",
      href: "/Events-Details",
      pdfurl: "pdfs/kidneyneeds_awareness_award_2025_Creator_and_Influencers.pdf",
      dowbtnText : "Download Poster",
      filename : "kidneyneeds_awareness_award_2025_Creator_and_Influencers",
       btnArrowUrl: "images/icons/Download_Arrow.svg"
     
    },
    {
      title: i18n.t("WorldKidneydayEventsData.Event1.title", {
        ns: "events",
      }),
      subTitle: i18n.t("WorldKidneydayEventsData.Event1.description", {
        ns: "events",
      }),
      thumbUrl: "images/home/kidneyneeds_awareness_award 2025_one_teacher_one_student_new.jpg",
     
      btnText: "Register now! 📞 +91 91116 06162",
      href: "/Events-Details",
      pdfurl: "pdfs/kidneyneeds_awareness_award_2025_one_teacher_one_student.pdf",
      dowbtnText : "Download Poster",
      filename : "kidneyneeds_awareness_award_2025_one_teacher_one_student",
      btnArrowUrl: "images/icons/Download_Arrow.svg"
     
    },
   
    
   
      // {
      //   title: i18n.t("WorldKidneydayEventsData.Event3.title", {
      //     ns: "events",
      //   }),
      //   subTitle: i18n.t("WorldKidneydayEventsData.Event3.description", {
      //     ns: "events",
      //   }),
      //   thumbUrl: "images/home/Drawing-poster-kidneyneeds-awareness-award-2025_Kanya-Kumari.png",
       
      //   btnText: "Register now! 📞 +91 91116 06162",
      //    href: "/Events-Details"
       
      // },
      // {
      //   title: i18n.t("WorldKidneydayEventsData.Event4.title", {
      //     ns: "events",
      //   }),
      //   subTitle: i18n.t("WorldKidneydayEventsData.Event4.description", {
      //     ns: "events",
      //   }),
      //   thumbUrl: "images/home/Drawing-poster-kidneyneeds-awareness-award-2025_KK.png",
       
      //   btnText: "Register now! 📞 +91 91116 06162",
      //    href: "/Events-Details"
       
      // },
      // {
      //   title: i18n.t("WorldKidneydayEventsData.Event5.title", {
      //     ns: "events",
      //   }),
      //   subTitle: i18n.t("WorldKidneydayEventsData.Event5.description", {
      //     ns: "events",
      //   }),
      //   thumbUrl: "images/home/wkd20251.png",
       
      //   btnText: "Register now! 📞 +91 91116 06162",
      //    href: "/Events-Details"
       
      // },
      // {
      //   title: i18n.t("WorldKidneydayEventsData.Event6.title", {
      //     ns: "events",
      //   }),
      //   subTitle: i18n.t("WorldKidneydayEventsData.Event6.description", {
      //     ns: "events",
      //   }),
      //   thumbUrl: "images/home/wkd20251.png",
       
      //   btnText: "Register now! 📞 +91 91116 06162",
      //    href: "/Events-Details"
       
      // },
      // {
      //   title: i18n.t("WorldKidneydayEventsData.Event7.title", {
      //     ns: "events",
      //   }),
      //   subTitle: i18n.t("WorldKidneydayEventsData.Event7.description", {
      //     ns: "events",
      //   }),
      //   thumbUrl: "images/home/wkd20251.png",
       
      //   btnText: "Register now! 📞 +91 91116 06162",
      //    href: "/Events-Details"
       
      // },
  ];
  const Eventsdada12 = [
    {
      title: i18n.t("WorldKidneydayEventsData.Event3.title", {
        ns: "events",
      }),
      subtitle1: i18n.t("WorldKidneydayEventsData.Event3.subtitle1", {
        ns: "events",
      }),
      subtitle11: i18n.t("WorldKidneydayEventsData.Event3.subtitle11", {
        ns: "events",
      }),
      subtitle12: i18n.t("WorldKidneydayEventsData.Event3.subtitle12", {
        ns: "events",
      }),
      subtitle13: i18n.t("WorldKidneydayEventsData.Event3.subtitle13", {
        ns: "events",
      }),
      subtitle2: i18n.t("WorldKidneydayEventsData.Event1.subtitle2", {
        ns: "events",
      }),
      subtitle3: i18n.t("WorldKidneydayEventsData.Event1.subtitle3", {
        ns: "events",
      }),
      subtitle31: i18n.t("WorldKidneydayEventsData.Event2.subtitle31", {
        ns: "events",
      }),
      subtitle32: i18n.t("WorldKidneydayEventsData.Event2.subtitle32", {
        ns: "events",
      }),
    
      subtitle4: i18n.t("WorldKidneydayEventsData.Event3.subtitle4", {
        ns: "events",
      }),
      subtitle5: i18n.t("WorldKidneydayEventsData.Event1.subtitle5", {
        ns: "events",
      }),
      thumbUrl: "images/home/kidneyneeds_awareness_award_2025_Dialysis_warriors_new.jpg",
     
      btnText: "Register now! 📞 +91 91116 06162",
       href: "/EventsDetails",
       pdfurl: "pdfs/kidneyneeds_awareness_award_2025_Dialysis_warriors_new.pdf",
       dowbtnText : "Download Poster",
       filename : "kidneyneeds_awareness_award_2025_Dialysis_warriors",
        btnArrowUrl: "images/icons/Download_Arrow.svg"
     
    },
    {
      title: i18n.t("WorldKidneydayEventsData.Event4.title", {
        ns: "events",
      }),
      subtitle1: i18n.t("WorldKidneydayEventsData.Event3.subtitle1", {
        ns: "events",
      }),
      subtitle11: i18n.t("WorldKidneydayEventsData.Event3.subtitle11", {
        ns: "events",
      }),
      subtitle12: i18n.t("WorldKidneydayEventsData.Event3.subtitle12", {
        ns: "events",
      }),
      subtitle13: i18n.t("WorldKidneydayEventsData.Event3.subtitle13", {
        ns: "events",
      }),
      subtitle2: i18n.t("WorldKidneydayEventsData.Event1.subtitle2", {
        ns: "events",
      }),
      subtitle3: i18n.t("WorldKidneydayEventsData.Event1.subtitle3", {
        ns: "events",
      }),
      subtitle31: i18n.t("WorldKidneydayEventsData.Event2.subtitle31", {
        ns: "events",
      }),
      subtitle32: i18n.t("WorldKidneydayEventsData.Event2.subtitle32", {
        ns: "events",
      }),
    
      subtitle4: i18n.t("WorldKidneydayEventsData.Event4.subtitle4", {
        ns: "events",
      }),
      subtitle5: i18n.t("WorldKidneydayEventsData.Event1.subtitle5", {
        ns: "events",
      }),
      thumbUrl: "images/home/kidneyneeds_awareness_award_2025_Patientcaregivers_new.jpg",
     
      btnText: "Register now! 📞 +91 91116 06162",
       href: "/EventsDetails",
       pdfurl: "pdfs/kidneyneeds_awareness_award_2025_Patientcaregivers_new.pdf",
       dowbtnText : "Download Poster",
       filename : "kidneyneeds_awareness_award_2025_Patientcaregivers",
        btnArrowUrl: "images/icons/Download_Arrow.svg"
     
    },
    {
      title: i18n.t("WorldKidneydayEventsData.Event2.title", {
        ns: "events",
      }),
      subtitle1: i18n.t("WorldKidneydayEventsData.Event2.subtitle1", {
        ns: "events",
      }),
      
      subtitle2: i18n.t("WorldKidneydayEventsData.Event1.subtitle2", {
        ns: "events",
      }),
      subtitle3: i18n.t("WorldKidneydayEventsData.Event1.subtitle3", {
        ns: "events",
      }),
      // subtitle31: i18n.t("WorldKidneydayEventsData.Event2.subtitle31", {
      //   ns: "events",
      // }),
      // subtitle32: i18n.t("WorldKidneydayEventsData.Event2.subtitle32", {
      //   ns: "events",
      // }),
      subtitle31: i18n.t("WorldKidneydayEventsData.Event1.subtitle31", {
        ns: "events",
      }),
      subtitle32: i18n.t("WorldKidneydayEventsData.Event1.subtitle32", {
        ns: "events",
      }),
      subtitle33: i18n.t("WorldKidneydayEventsData.Event1.subtitle33", {
        ns: "events",
      }),
      subtitle4: i18n.t("WorldKidneydayEventsData.Event2.subtitle4", {
        ns: "events",
      }),
      subtitle5: i18n.t("WorldKidneydayEventsData.Event1.subtitle5", {
        ns: "events",
      }),
      thumbUrl: "images/home/kidneyneeds_awareness_award_2025_Creator_and_Influencers_new.jpg",
     
      btnText: "Register now! 📞 +91 91116 06162",
       href: "/EventsDetails",
       pdfurl: "pdfs/Kidneyneeds_awareness_award_2025_creators_new.pdf",
       dowbtnText : "Download Poster",
       filename : "kidneyneeds_awareness_award_2025_Creator_and_Influencers",
        btnArrowUrl: "images/icons/Download_Arrow.svg"
     
    },
    {
      title: i18n.t("WorldKidneydayEventsData.Event1.title", {
        ns: "events",
      }),
      subtitle1: i18n.t("WorldKidneydayEventsData.Event1.subtitle1", {
        ns: "events",
      }),
    
      subtitle2: i18n.t("WorldKidneydayEventsData.Event1.subtitle2", {
        ns: "events",
      }),
      subtitle3: i18n.t("WorldKidneydayEventsData.Event1.subtitle3", {
        ns: "events",
      }),
      subtitle31: i18n.t("WorldKidneydayEventsData.Event1.subtitle31", {
        ns: "events",
      }),
      subtitle32: i18n.t("WorldKidneydayEventsData.Event1.subtitle32", {
        ns: "events",
      }),
      subtitle33: i18n.t("WorldKidneydayEventsData.Event1.subtitle33", {
        ns: "events",
      }),
      subtitle4: i18n.t("WorldKidneydayEventsData.Event1.subtitle4", {
        ns: "events",
      }),
      subtitle5: i18n.t("WorldKidneydayEventsData.Event1.subtitle5", {
        ns: "events",
      }),
      thumbUrl: "images/home/kidneyneeds_awareness_award 2025_one_teacher_one_student_new.jpg",
     
      btnText: "Register now! 📞 +91 91116 06162",
      href: "/Events-Details",
      pdfurl: "pdfs/kidneyneeds_awareness_award_2025-One_Teacher_and_One_student_new.pdf",
      dowbtnText : "Download Poster",
      filename : "kidneyneeds_awareness_award_2025_one_teacher_one_student",
      btnArrowUrl: "images/icons/Download_Arrow.svg"
     
    },
  
     
     
     
  ];
 

  return {
    Eventsdada1,
   Eventsdada12 

  };
};
