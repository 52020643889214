// import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";


export default function EventsPost({
  title,
  subTitle = "",
  thumbUrl,
  date,
  btnText,
  href,
  socialShare,
  variant,
  gradient,
  filename,
  pdfurl,
  dowbtnText,
  btnArrowUrl,
}) {
  return (
    <div
      style={{
        backgroundImage: gradient
          ? `linear-gradient(${gradient?.a}, ${gradient?.b})`
          : "",
      }}
      className={`cs_post cs_style_1 ${variant ?? ""}`}
    >
      {/* Thumbnail Section */}
      {thumbUrl && (
        <Link to={href} className="cs_post_thumb cs_view_mouse">
          <img loading="lazy" src={thumbUrl} alt={title} />
        </Link>
      )}

      <div className="cs_post_info">
       
        <div>
  
</div>

  <div className="registration-inquiry">
      <h3 className="side-heading">Registration Enquiry</h3>

      {/* Call Button */}
      <div className="contact-item">
        <Link to="tel:+919025585770">
          <i className="fa-solid fa-phone icon"></i>
          <span>Phone Call: +91 91116 06162</span>
        </Link>
      </div>

      {/* WhatsApp Chat */}
      <div className="contact-item">
      <Link 
  to={`https://wa.me/919111606162?text=${encodeURIComponent("Hi, I want to participate in World Kidney Day Event 2025.")}`} 
  target="_blank"
>
  <i className="fa-brands fa-whatsapp icon whatsapp"></i>
  <span>WhatsApp: Chat Now</span>
</Link>

      </div>

      {/* Email */}
      {/* <div className="contact-item">
        <Link to="mailto:support@kidneyneeds.com">
          <i className="fa-solid fa-envelope icon email"></i>
          <span>Email: support@kidneyneeds.com</span>
        </Link>
      </div> */}
    </div>

       
        {/* {btnText && !pdfurl && (
  <div className="cs_mediu">
    <a href="tel:9111606162" className="cs_post_btn">
      {btnText}
    </a>
  </div>
)} */}
        {pdfurl && dowbtnText && (
          <a href={pdfurl} download={filename} className="cs_download_btn">
            {dowbtnText}
            {btnArrowUrl && (
              <img
                src={btnArrowUrl}
                alt="Download arrow"
                className="cs_download_icon"
              />
            )}
          </a>
        )}
      </div>
    </div>
  );
}
