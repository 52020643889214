import React from "react";
import BannerSectionStyle5 from "../Section/BannerSection/BannerSectionStyle5";
import ContactForm from "../ContactForm";
import Section from "../Section";
import ContactInfoSection from "../Section/ContactInfoSection";
import { pageTitle } from "../../helpers/PageTitle";
import { CustomToastContainer } from "../../helpers/toastHelper";
import { useTranslation } from "react-i18next";


export default function Contact() {
  pageTitle("Contact");

  const { t } = useTranslation(["contact"]);

  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/contact/banner_bg.svg"
        // imgUrl="/images/contact/Kidneyneeds-first-team.png"
         imgUrl="/images/contact/Kidneyneeds-first-team_contact.png"
        title={t("contact.title")}
        subTitle={t("contact.subTitle")}
      />
      <div className="container cs_mt_minus_110">     
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <ContactForm />
          </div>
        </div>
      </div>
      <Section
        topMd={200}
        topLg={150}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <CustomToastContainer />
        <ContactInfoSection sectionTitle="Find Us Here" />
      </Section>
    </>
  );
}
